
import type { ResultsStruct, Ticket, TicketLocation } from './interfaces'
import { getDynamicTimes } from './helpers'

export const resultsStruct: ResultsStruct = {
    hits: [],
    page: 0,
    nbHits: 0,
    nbPages: 0,
    hitsPerPage: 6,
    processingTimeMS: 0,
    query: '',
}

export const locationStruct: TicketLocation = {
    isSearching: false,
    query: '',
    result: {},
    results: structuredClone(resultsStruct),
    showResults: false,
    selectedIndex: 0,
}

export const ticketsStruct: Ticket[] = [
    {
        id: 0,
        errors: {
            origin: false,
            destination: false,
            date: false,
            time: false,
        },
        origin: structuredClone(locationStruct),
        destination: structuredClone(locationStruct),
        date: '',
        time: '',
        showTimeTable: false,
        bags: '',
        pax: 1,
        times: getDynamicTimes(),
    },
]
