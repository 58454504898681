
import type { StorageItem } from "./interfaces"
export function getDynamicTimes() {
    const offset = 12;
    const times = Array.from({ length: 48 }, (_, i) =>
        `${ String((Math.floor((i + offset) / 2) % 24)).padStart(2, '0') }:${ (i + offset) % 2 ? '30' : '00' }`
    )
    return times;
}
export const isToday = (dateString: string) => {
    const inputDate = new Date(dateString.split('/').reverse().join('-')) // Convert to YYYY-MM-DD format
    const today = new Date()

    return inputDate.getDate() === today.getDate() &&
        inputDate.getMonth() === today.getMonth() &&
        inputDate.getFullYear() === today.getFullYear()
}
export function getCurrentTime() {
    // return '20:00'
    const now = new Date()
    const minutes = now.getMinutes()
    const hours = now.getHours()

    if (minutes < 30) {
        // Round up to the next half hour
        return `${ hours.toString().padStart(2, '0') }:30`
    } else {
        // Round up to the next hour
        const nextHour = (hours + 1) % 24
        return `${ nextHour.toString().padStart(2, '0') }:00`
    }
}


export const ticketStorage = {
    getItem(key: string): any {
        const storedItem = localStorage.getItem(key)
        if (!storedItem) return null

        const parsedItem: StorageItem = JSON.parse(storedItem)

        if (parsedItem.expiry && new Date(parsedItem.expiry) < new Date()) {
            this.removeItem(key)
            return null
        }

        return parsedItem.value
    },
    setItem(key: string, value: any, expiryInSeconds: number = 60 * 5): void {
        const expiryDate = new Date()
        expiryDate.setSeconds(expiryDate.getSeconds() + expiryInSeconds)

        const itemToStore: StorageItem = {
            value,
            expiry: expiryDate.toISOString(),
        }

        localStorage.setItem(key, JSON.stringify(itemToStore))
    },
    removeItem(key: string): void {
        localStorage.removeItem(key)
    },
}
