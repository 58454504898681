import Alpine from 'alpinejs'
import whenDomReady from "when-dom-ready"
import ticketBookingForm from './TicketBooking/TicketBooking'

declare global {
    interface Window {
        Alpine: typeof Alpine;
        algoliaAppId: string;
        algoliaSearchOnlyApiKey: string;
    }
}
whenDomReady(async () => {
    ticketBookingForm()
})

// Hot Module Logic
if ((import.meta as any).hot) {
    (import.meta as any).hot.on('vite:beforeFullReload', () => {
        throw '(skipping full reload)'
    })
}
