import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import mask from '@alpinejs/mask'
import debounce from "lodash/debounce"
import { searchClient } from "@algolia/client-search"
import { TempusDominus, DateTime, Unit } from '@eonasdan/tempus-dominus'
import { ticketStorage, getDynamicTimes } from './helpers'
import { ticketsStruct, resultsStruct, locationStruct } from './structs'
import type { Ticket, SearchResult, OptionPosition, AutoselectField, Errors } from './interfaces'

import './directives'

Alpine.plugin(persist)
Alpine.plugin(mask)

export class TicketBooking {
    client: ReturnType<typeof searchClient> | null = null
    currentTime: string
    errorsCount: number = 0
    debouncedInputSearch: ReturnType<typeof debounce>
    formNotice: string = ''
    index: number | null = null
    isFixed: boolean = false
    isSubmitting: boolean = false
    styleContainer: CSSStyleDeclaration = {} as CSSStyleDeclaration
    styleOuterWrap: CSSStyleDeclaration = {} as CSSStyleDeclaration
    showFormNotice: boolean = false
    showModal: boolean = false
    showCart: boolean = false
    success: string = 'Thank you for your message. We will get in touch with you shortly'
    typingIndex: number = 0
    tickets: Ticket[]
    formErrorsClass: string = 'outline outline-1 outline-red-600 outline-offset-1'
    constructor() {
        this.currentTime = ''
        this.debouncedInputSearch = debounce((ticket, key, hitsPerPage) => this.inputSearch(ticket, key, hitsPerPage), 200)
        this.formNotice = ''
        this.tickets = Alpine.$persist(ticketsStruct).using(ticketStorage) as unknown as Ticket[]
        this.typingIndex = 0
    }

    private processFlight(el: HTMLElement, isRoundTrip: boolean = false) {
        if (this.tickets.length >= 5) {
            this.showTempMessage('You can only book up to 5 flights', 3000)
            return
        }
        const prevTicket = this.tickets[this.tickets.length - 1]

        if (isRoundTrip) {
            if (!prevTicket.origin.result.name || !prevTicket.destination.result.name) {
                if (!prevTicket.origin.result.name) {
                    this.highlightAndFocus('origin', prevTicket)
                } else {
                    this.highlightAndFocus('destination', prevTicket)
                }
                return
            }
        }

        const newId = this.tickets.length
        const newTicket = isRoundTrip ?
            this.createFlightTicket(newId, prevTicket)
            : this.createFlightTicket(newId)

        this.tickets.push(newTicket)

        Alpine.nextTick(() => {
            const idx = `ticket-date-${ newId }`
            const dateInputEl: HTMLElement | null = document.getElementById(idx)
            this.initDatePicker?.(dateInputEl, prevTicket)
            this.initAutocomplete?.()
            this.setParentElHeight(el)
        })

    }
    addFlight(el: HTMLElement): void {
        this.processFlight(el)
    }
    addRoundTrip(el: HTMLElement): void {
        this.processFlight(el, true)
    }
    blurSelect(ticket: Ticket, key: AutoselectField, index: number): void {
        if (ticket[key].results.hits.length) {
            const result = ticket[key].results.hits[index] as SearchResult
            ticket[key].query = result.name
            ticket[key].result = result
            ticket[key].showResults = false
            ticket[key].selectedIndex = index
        }
    }
    book(): void {
        this.validateFields()
        console.log(this.tickets);

        if (this.countErrors() !== 0) {
            this.formNotice = "Please complete all fields to proceed."
            this.showFormNotice = true
        } else {
            this.showModal = true
        }
    }
    clickAutocompleteInput(ticket: Ticket, key: AutoselectField): void {
        this.showResults(ticket, key)
    }
    closeModal(form: HTMLFormElement): void {
        this.isSubmitting = false;
        this.showModal = false
        const successElement = document.getElementById('fluentform_1_success')
        if (successElement) {
            successElement.remove()
            this.ticketsReset()
        }
    }
    clearResult(ticket: Ticket, key: AutoselectField): void {
        ticket[key].query = ''
        ticket[key].result = {}
        ticket[key].results = structuredClone(resultsStruct)
        ticket.errors[key]
    }
    centerCurrentTime(container: HTMLElement): void {
        Alpine.nextTick(() => {
            const listItem = container.querySelector('.current') as HTMLElement
            if (!listItem) return

            const elTop = listItem.offsetTop
            const containerHeight = container.clientHeight
            const topPos = elTop - containerHeight / 2 + listItem.clientHeight / 2 + 40 * 3

            setTimeout(() => {
                container.scrollTo({
                    top: topPos,
                    behavior: 'smooth',
                })
            }, 300)
        })
    }
    createFlightTicket(id: number, ticket?: Ticket): Ticket {
        return {
            id,
            origin: this.createSearchField(ticket?.destination),
            destination: this.createSearchField(ticket?.origin),
            errors: {
                origin: false,
                destination: false,
                date: false,
                time: false,
            },
            date: '',
            time: '',
            showTimeTable: false,
            pax: ticket?.pax ?? '',
            bags: ticket?.bags ?? '',
            times: ticket?.times || getDynamicTimes(),
        }
    }
    createSearchField(source?: any): any {
        return {
            isSearching: false,
            query: source?.query || '',
            result: source?.result || {},
            results: JSON.parse(JSON.stringify(resultsStruct)),
            showResults: false,
            selectedIndex: 0,
        }
    }
    deleteTicket(ticket: Ticket): void {
        this.tickets.splice(this.tickets.indexOf(ticket), 1)
    }
    formatSearchValues(values: string[]): string {
        return values.filter(value => value).join(' - ')
    }

    formClear(el: HTMLElement): void {
        this.ticketsReset()
        this.formNotice = ''
        this.showFormNotice = false
        this.typingIndex = 0
        Alpine.nextTick(() => {
            this.styleContainer.height = `${ el.clientHeight }px`
        })
    }
    getSeparatorClass(index: number): { separator: boolean } {
        return {
            separator: index >= 0 && index < this.tickets.length - 2
        }
    }

    handleScrollEnd(event: Event, timeList: HTMLElement, ticketId: number): void {
        const options = Array.from(timeList.querySelectorAll('.time-option')) as HTMLElement[]
        const optionPositions: OptionPosition[] = options.map((option, index) => ({
            index,
            position: option.offsetTop,
            time: option.textContent?.trim() || '',
        }))

        const currentScrollPosition = timeList.scrollTop
        const nearestOption = optionPositions.reduce((nearest, current) => {
            return Math.abs(current.position - currentScrollPosition) < Math.abs(nearest.position - currentScrollPosition)
                ? current
                : nearest
        })

        const ticketToUpdate = this.tickets.find((ticket: Ticket) => ticket.id === ticketId)
        if (ticketToUpdate) {
            ticketToUpdate.time = nearestOption.time
        }
    }
    highlightAndFocus(field: keyof Errors, ticket: Ticket): void {
        const inputId = `ticket-${ field }-${ ticket.id }`
        const input = document.getElementById(inputId)

        this.showTempMessage(`Select ${ field } to continue`, 3000)

        ticket.errors[field] = true

        if (input) {
            input.focus()
        }
    }

    isEmpty() {
        return this.tickets.length === 0
    }
    isMoreThanOne() {
        return this.tickets.length > 1
    }
    initialize(outerWrapEl: HTMLElement) {
        this.stick(outerWrapEl)
        Alpine.nextTick(() => {
            const dateInputs = document.querySelectorAll('.date-input')
            for (const dateInputEl of Array.from(dateInputs)) {
                this.initTempusDominus(dateInputEl as HTMLElement)
            }
        })
    }

    initTempusDominus(el: HTMLElement | null, minDate: DateTime = new DateTime()): void {
        if (el) {
            new TempusDominus(el, {
                display: {
                    icons: {
                        type: 'icons',
                        time: 'fa-solid fa-clock',
                        date: 'fa-solid fa-calendar',
                        up: 'fa-solid fa-arrow-up',
                        down: 'fa-solid fa-arrow-down',
                        previous: 'svg-arrow prev',
                        next: 'svg-arrow next',
                        today: 'fa-solid fa-calendar-check',
                        clear: 'fa-solid fa-trash',
                        close: 'fa-solid fa-xmark'
                    },
                    viewMode: 'calendar',
                    components: {
                        minutes: false,
                        hours: false,
                        seconds: false,
                    },
                },
                restrictions: {
                    minDate: minDate,
                },
                localization: {
                    locale: 'en-GB',
                    format: 'dd/MM/yyyy',
                },
            })
        }
    }
    initDatePicker(dateInputEl: HTMLElement | null, prevTicket?: Ticket): void {
        let date = new DateTime()
        if (prevTicket) {
            const prevDate = prevTicket.date || ''
            if (prevDate) {
                const [day, month, year] = prevDate.split('/')

                const parsedDate = DateTime.fromString(`${ day }/${ month }/${ year }`, {
                    format: 'dd/MM/yyyy',
                })

                if (DateTime.isValid(parsedDate)) {
                    date = parsedDate.manipulate(0, Unit.date)
                } else {
                    console.error("Invalid DateTime format.")
                }
            }
        }
        this.initTempusDominus(dateInputEl, date)
    }
    initTimePicker(): void {
        const containers = document.querySelectorAll('.time-picker-list-wrap') as NodeListOf<HTMLElement>

        containers.forEach((container) => {
            this.centerCurrentTime(container)
        })
    }
    initAutocomplete(): void {
        this.client = searchClient(
            (window as any).algoliaAppId,
            (window as any).algoliaSearchOnlyApiKey
        )
    }
    async inputSearch(ticket: Ticket, key: AutoselectField, hitsPerPage: number): Promise<void> {
        this.initAutocomplete()
        const query = ticket[key].query

        if (!query.trim()) {
            ticket[key].results.hits = []
            return
        }

        ticket[key].isSearching = true

        try {
            const results = await this.client?.searchSingleIndex({
                indexName: "airports",
                searchParams: {
                    query,
                    hitsPerPage,
                }
            })

            if (results?.hits.length) {
                ticket[key].results.hits = results.hits
            }
            ticket[key].selectedIndex = 0
            ticket[key].showResults = true
        } catch (error) {
            console.error("Error fetching search results:", error)
        } finally {
            ticket[key].isSearching = false
        }

    }
    onKeyup(event: KeyboardEvent, resultsEl: HTMLElement, ticket: Ticket, key: AutoselectField): void {
        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
            this.showResults(ticket, key)
        }

        if (event.key === "ArrowDown" && ticket[key].selectedIndex < ticket[key].results.hits.length - 1) {
            ticket[key].selectedIndex++
            this.scroll(true, resultsEl)
        }

        if (event.key === "ArrowUp" && ticket[key].selectedIndex > 0) {
            ticket[key].selectedIndex--
            this.scroll(false, resultsEl)
        }

        if (event.key === "Enter") {
            const result: SearchResult = ticket[key].results.hits[ticket[key].selectedIndex]

            ticket[key].query = result.name
            ticket[key].result = result
            ticket[key].showResults = false
            ticket.errors[key] = false
            if (this.countErrors() === 0) {
                this.showFormNotice = false
            }
        }
    }
    scroll(down: boolean = true, resultsEl: HTMLElement): void {
        const length = 54 * 0.75
        resultsEl.scrollBy({ top: down ? length : -length, behavior: "smooth" })
    }
    showTempMessage(msg: string, delay: number = 3000): void {
        this.formNotice = msg
        this.showFormNotice = true
        setTimeout(() => {
            this.formNotice = ''
            this.showFormNotice = false
        }, delay)
    }
    selectAutocompleteResult(ticket: Ticket, result: SearchResult, index: number, key: AutoselectField): void {
        ticket[key].query = result.name
        ticket[key].result = result
        ticket[key].showResults = false
        ticket[key].selectedIndex = index
        ticket.errors[key] = false
        if (this.countErrors() === 0) {
            this.showFormNotice = false
        }
    }
    selectDate(e: Event, ticket: Ticket): void {
        const target = e.target as HTMLInputElement
        ticket.date = target.value
        ticket.errors.date = false
        if (this.countErrors() === 0) {
            this.showFormNotice = false
        }
        this.updateTimes(ticket)
    }
    selectTime(ticket: Ticket, time: string, el: HTMLElement): void {
        ticket.time = time
        ticket.errors.time = false
        this.currentTime = time
        setTimeout(() => {
            this.centerCurrentTime(el)
        }, 100)

        if (this.countErrors() === 0) {
            this.showFormNotice = false
        }
    }
    showResults(ticket: Ticket, key: AutoselectField): void {
        if (ticket[key].results.hits.length) {
            ticket[key].showResults = true
        }
    }
    submit() {
        if (this.isSubmitting) return;

        const form = document.querySelector('#fluentform_1') as HTMLFormElement | null;
        if (form) {
            const hiddenField = form.querySelector('input[name="tickets"]') as HTMLInputElement | null;

            this.isSubmitting = true;

            if (hiddenField) {
                hiddenField.value = JSON.stringify(this.tickets);
            }

            jQuery(form).trigger('submit');

            jQuery(form).on('fluentform_submission_failed', () => {
                this.isSubmitting = false;
            });
        }
    }

    setParentElHeight(el: HTMLElement): void {
        const parent = el.parentElement

        if (parent) {
            this.styleContainer.height = `${ el.clientHeight }px`
        }
    }

    applySticky(el: HTMLElement) {
        const mainHeaderEl = document.querySelector('.booking-form-container')

        if (mainHeaderEl) {
            const headerRect = mainHeaderEl.getBoundingClientRect()

            if (headerRect.bottom <= 0) {
                this.isFixed = true
                if (window.innerWidth < 768) {
                    this.styleOuterWrap.height = `100%`
                    this.styleOuterWrap.paddingBottom = `120px`
                }
            } else {
                this.isFixed = false
                this.showCart = false
                if (window.innerWidth < 768) {
                    this.styleOuterWrap.height = ``
                    this.styleOuterWrap.paddingBottom = `0`
                }
            }
            Alpine.nextTick(() => {
                this.styleContainer.height = `${ el.children[0].clientHeight }px`
            })

        }
    }
    stick(el: HTMLElement): void {
        Alpine.nextTick(() => {
            this.applySticky(el)
        })

        const handleScroll = () => this.applySticky(el)
        const handleResize = () => this.applySticky(el)
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleResize)
    }

    ticketsReset() {
        this.tickets.splice(1, this.tickets.length - 1)
        this.tickets[0].destination = structuredClone(locationStruct)
        this.tickets[0].origin = structuredClone(locationStruct)
        this.tickets[0].pax = 1
        this.tickets[0].bags = ''
        this.tickets[0].date = ''
        this.tickets[0].time = ''
        this.tickets[0].times = getDynamicTimes()
    }
    toggleCart(): void {
        this.showCart = !this.showCart
    }
    toggleTimeTable(ticket: Ticket, delay = 0): void {
        setTimeout(() => {
            ticket.showTimeTable = !ticket.showTimeTable
        }, delay)
    }
    updateTimes(ticket: Ticket) {
        ticket.times = getDynamicTimes()
    }
    countErrors() {
        let errorsCount = 0
        this.tickets.forEach(ticket => {
            errorsCount += Object.values(ticket.errors).filter(Boolean).length
        })
        return errorsCount
    }
    validateFields(): void {
        const fields: (keyof Errors)[] = ['origin', 'destination', 'date', 'time']
        for (const ticket of this.tickets) {
            for (const field of fields) {

                const isEmpty = field === 'origin' || field === 'destination'
                    ? !(ticket[field].result && Object.keys(ticket[field].result).length)
                    : !ticket[field]

                if (isEmpty) {
                    ticket.errors[field] = true
                }
            }
        }
    }
    static createObject(): any {
        const instance = new TicketBooking();
        return new Proxy(instance, {
            get(target, prop, receiver) {
                return Reflect.get(target, prop, receiver)
            },
            set(target, prop, value, receiver) {
                return Reflect.set(target, prop, value, receiver)
            }
        })
    }
}
export default (): void => {
    window.Alpine = Alpine
    window.algoliaAppId = import.meta.env.VITE_ALGOLIA_APP_ID as string
    window.algoliaSearchOnlyApiKey = import.meta.env.VITE_ALGOLIA_SEARCH_ONLY_API_KEY as string

    document.addEventListener('alpine:init', () => {
        Alpine.store('data', TicketBooking.createObject())
        Alpine.magic('data', () => Alpine.store('data'))
    })

    Alpine.start()
}
